import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import "../App.css";

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

export const Ourproject = () => {
    const heading1 = useRef(null);

    useEffect(() => {
        gsap.fromTo(
            heading1.current,
            { x: 50 }, // Initial state: translateX 0
            {
                x: "-60%", // Final state: translateX to -100%
                scrollTrigger: {
                    trigger: heading1.current,
                     // Assuming #main is your scroll container
                    scrub: 0.7
                }
            }
        );
    }, []);

    return (
        <div id="name-div" >
            <h1 ref={heading1}>Affy Cloud IT Solutions Affy Cloud IT Solutions Affy Cloud IT Solutions Affy Cloud IT Solutions  Affy Cloud IT Solutions </h1>
            
        </div>
    );
};

export default Ourproject;
