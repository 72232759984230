import React, { useEffect } from 'react';
import { TopNavbar } from '../Componants/TopNavbar'
import { Navbar } from '../Componants/Navbar'
import { Footer } from '../Componants/Footer'
import ai1 from "../img/ai1.jpg"
import ai2 from "../img/ai2.jpeg"


export const AI = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div>
            <TopNavbar />
            <div className="container-fluid position-relative p-0">
                <Navbar />
                <div className="container-fluid bg-primary py-5 bg-header" style={{ marginBottom: '60px' }}>
                    <div className="row py-5">
                        <div className="col-12 pt-lg-5 mt-lg-5 text-center">
                            <h1 className="display-4 text-white animated zoomIn">AI Development</h1>
                            <a href="/" className="h6 text-white">Home</a>
                            <i className="fa fa-angle-right text-white px-2"></i>
                            <a className="h6 text-white">Services</a>
                            <i className="fa fa-angle-right text-white px-2"></i>
                            <a className="h6 text-white">AI Development</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid overflow-hidden px-lg-0">
                <div className="container feature py-3 px-lg-0">
                    <div className="row g-5 mx-lg-0">
                        <div className="col-lg-6 feature-text wow fadeInUp" >
                            <h1 className="mb-4 fw-medium fontf color">AI Development</h1>
                            <div className="d-flex mb-5 wow fadeInUp" >
                                <h5>Welcome to our AI-powered development service! We're here to make your projects better and more efficient using the magic of Artificial Intelligence. We create special solutions just for you, so your business can do even better. Let's work together and make amazing things happen.</h5>
                            </div>
                            <div className="d-flex mb-5 wow fadeIn" data-wow-delay="0.5s">
                                <div>
                                    <h5 className='h3  fw-medium fontf color'>Our Approach</h5>
                                    <h5>At Affy Cloud, we're all about teamwork in AI development. Our expert team of engineers and data scientists partners with you closely. We listen to your goals, study your data, and then create customized AI solutions that fit perfectly with what you want to achieve in your business.</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 pe-lg-0 wow fadeInRight" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute img-fluid w-100 h-100" src={ai1} style={{ objectFit: 'cover' }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid overflow-hidden mt-5 mb-5 px-lg-0">
                <div className="container feature px-lg-0">
                    <div className="row g-5 mx-lg-0">
                        <div className="col-lg-6 pe-lg-0 wow fadeInRight" style={{ minHeight: '400px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute img-fluid w-100 h-100" src={ai2} style={{ objectFit: 'cover' }} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 feature-text px-5 fadeInUp"  >
                            <h1 className="mb-4 fw-medium fontf color">What is AI-Based Development ?</h1>
                            <div className="d-flex mb-5 wow fadeInUp" >
                                <h5>AI-based development uses fancy computer techniques to make really smart software. This software can handle tasks by itself, like predicting outcomes or understanding human language. It can also adjust to different situations and recognize objects in pictures. This technology helps businesses work better and gives users a more personalized experience. </h5>
                            </div>
                            <div className=" mb-5 ">
                                <div>
                                    <h5 className='h3 fw-medium fontf color'>Benefits of AI-Based Development</h5>
                                    <h5>The benefits of blockchain are increasing trust, security and transparency among member organizations by improving the traceability of data shared across a business network, plus delivering cost savings through new efficiencies.</h5>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
