const BASE_URL = "https://affy-main.affyclouditsolutions.com/api";
export const IMG_URL = 'https://affy-main.affyclouditsolutions.com/'

export const LOGIN_URL = BASE_URL + "/login";
export const CREATE_BLOG = BASE_URL + "/blogs/store";
export const GET_BLOGS = BASE_URL + "/blogs";
export const EDIT_BLOGS = BASE_URL + "/blogs/{id}";

export const BLOGS_DETAIL = BASE_URL + "/blogs";
export const DELETE_BLOG = BASE_URL + "/blogs";

// export const CREATE_INC = BASE_URL + "/enquiry/store";
export const CREATE_INC = BASE_URL + "/enquiry/store";
export const GET_INCQUIRY = BASE_URL + "/enquiry";
export const REMARK_INC = BASE_URL + "/enquiry/remark";



export const RESET_PASS = BASE_URL + "/password/email";

