import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import "../App.css"
import stock from "../img/stock.avif"
import inventary from "../img/inventary.jpg"
import epr from "../img/epr.jpg"
import ecom from "../img/e-com.jpg"


gsap.registerPlugin(ScrollTrigger);

export const Ourproject = () => {
    
    const heading = useRef(null);
    useEffect(() => {
        gsap.fromTo(
            heading.current,
            { scale: 0.7 },
            {
                scale: 1,
                duration: 2,  
                ease: 'power2.out', 
                scrollTrigger: {
                    trigger: heading.current,
                    start: 'top 80%',  
                    end: 'top 20%',  
                    scrub: 1,  
                },
            }
        );

    }, []);
    return (

        <div class="container py-5 mt-3">
            <div class="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                <h1 class="mb-4" ref={heading}>Our Projects</h1>
                <p className='text-white mb-3 text-center lead fs-4'>Cutting-edge project leveraging advanced technologies for global market optimization.</p>
            </div>
            <div class="row g-5">
                <div class="col-md-6 zoomIn1">
                    <div class=" border border-white  bg-light rounded overflow-hidden">
                        <div class="blog-img position-relative overflow-hidden">
                            <img class="img-fluid p-3" src={stock} alt="" />
                        </div>
                        <div class="px-4 mb-5 mt-2">
                            <h4 class="mb-3 text-black">Stock Market Application</h4>
                            <h6 className='text-black'> Real-time stock data, personalized Trade, news updates, and analytics for informed investment decisions anytime, anywhere.</h6>

                        </div>
                    </div>
                </div>
                <div class="col-md-6 zoomIn1">
                    <div class=" border border-white  bg-light rounded overflow-hidden">
                        <div class="blog-img position-relative overflow-hidden">
                            <img class="img-fluid p-3" src={inventary} alt="" />
                        </div>
                        <div class="px-4 mb-5 mt-2">
                            <h4 class="mb-3 text-black">Inventory management software</h4>
                            <h6 className='text-black'>Efficiently track, manage, and optimize inventory with intuitive software designed for seamless business operations.</h6>

                        </div>
                    </div>
                </div>
                <div class="col-md-6 zoomIn1">
                    <div class=" border border-white bg-light rounded overflow-hidden">
                        <div class="blog-img position-relative overflow-hidden">
                            <img class="img-fluid p-3" src={epr} alt="" />
                        </div>
                        <div class="px-4 mb-5 mt-2">
                            <h4 class="mb-3 text-black">EPR software</h4>
                            <h6 className='text-black'>Streamline enterprise resource planning with integrated software for efficient business management and  operational optimization.</h6>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 zoomIn1">
                    <div class=" border border-white  bg-light rounded overflow-hidden">
                        <div class="blog-img position-relative overflow-hidden">
                            <img class="img-fluid p-3" src={ecom} alt="" />
                        </div>
                        <div class="px-4 mb-4 mt-2">
                            <h4 class="mb-3 text-black">E-commerce</h4>
                            <h6 className='text-black'>Online platform enabling seamless buying and selling of products, supported by secure payment and logistics solutions.</h6>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    )
}
