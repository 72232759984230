import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import slide1 from "../img/estetico1.png"
import slide2 from "../img/panatronix.png"
import slide3 from "../img/stockmint.jpg"
import slide4 from "../img/colabTool.png"
import slide5 from "../img/eleganza.png"
import slide6 from "../img/thread.png"

const testimonials = [
    {
        img: slide1,
        title: 'Estetico Studio',
        text: "Affy Cloud's customized IT solutions brought our architectural designs to life, elevating our creativity and efficiency. A perfect blend of art and technology!"
    },
    {
        img: slide2,
        title: 'Panatronix Solutions',
        text: "Affy Cloud's bespoke IT solutions masterfully transformed our events, ensuring impeccable execution and surpassing our expectations."
    },
    {
        img: slide3,
        title: 'Stock Mint',
        text: "Affy Cloud's tailored IT solutions leveraged our trading platform, delivering lightning-fast speeds and seamless performance. A game-changer for our business!"
    },
    {
        img: slide4,
        title: 'Colab Tools',
        text: "Affy Cloud's innovative IT solutions streamlined our construction operations, enhancing collaboration and productivity. A trusted partner in our success!"
    },
    {
        img: slide5,
        title: 'Eleganza Estate',
        text: "Affy Cloud's expertise transformed our real estate website, elevating user experience and driving sales. Their optimized solutions are the perfect fit for our growing business!"
    },
    {
        img: slide6,
        title: '~Threads Brew',
        text: "Affy Cloud's innovative IT solutions enabled us to create immersive online experiences, driving customer engagement, and setting ThreadsBrew apart in the fashion world"
    }
];

export const Testimonial = () => {
    return (
        <Carousel data-bs-theme="dark">
            {testimonials.map((testimonial, index) => (
                <Carousel.Item key={index}>
                    <div className='container'>
                        <div className='row mt-4'>
                            <div className='col' style={{ textAlign: 'center' }}>
                                <img className="w-50 h-100" src={testimonial.img} alt={testimonial.title} />
                            </div>
                        </div>
                        <div className='col'>
                            <h3 className='text-center text-black mt-2'>{testimonial.title}</h3>
                            <div className="stars" style={{ textAlign: 'center', color: 'gold' }}>
                                <i className="bi bi-star-fill" style={{ margin: '0 5px' }}></i>
                                <i className="bi bi-star-fill" style={{ margin: '0 2px' }}></i>
                                <i className="bi bi-star-fill" style={{ margin: '0 2px' }}></i>
                                <i className="bi bi-star-fill" style={{ margin: '0 2px' }}></i>
                                <i className="bi bi-star-fill" style={{ margin: '0 2px' }}></i>
                            </div>
                            <p className='text-center mb-5 text-black'>{testimonial.text}</p>
                        </div>
                    </div>
                </Carousel.Item>
            ))}
        </Carousel>
    );
}
