import React from "react";
import { TailSpin } from "react-loader-spinner";

export const Loader = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
            <div>
                <TailSpin
                    height={80}
                    width={80}
                    color="#01B3B5"
                />
            </div>
        </div>
    );
};
